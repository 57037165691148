// Recidiviz - a data platform for criminal justice reform
// Copyright (C) 2024 Recidiviz, Inc.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
// =============================================================================

import React from "react";

import image from "../assets/static/images/404.png";

function NotFound() {
  return (
    <div className="main-content bgc-grey-100">
      <div className="pos-a t-0 l-0 bgc-white w-100 h-100 d-f fxd-r fxw-w ai-c jc-c pos-r p-30">
        <div className="mR-60">
          <img alt="#" src={image} />
        </div>
        <div className="d-f jc-c fxd-c">
          <h1
            className="mB-30 fw-900 lh-1 c-red-500"
            style={{ fontSize: "60px" }}
          >
            404
          </h1>
          <h3 className="mB-10 fsz-lg c-grey-900 tt-c">Oops Page Not Found</h3>
          <p className="mB-30 fsz-def c-grey-700">
            The page you are looking for does not exist or has been moved.
          </p>
          <div>
            <a href="/" type="primary" className="btn btn-primary">
              Go to Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
